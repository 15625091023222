<template>
  <div class="container container-top">
    <div class="pc">
      <div class="h5 main padding-top-60">1:1 문의목록</div>
      <div class="flex-between padding-top-40">
        <div class="subtitle6">전체 문의 {{ itemFiltered.count }}개</div>
        <div>
          <button class="button is-primary body4-medium"
                  style="width:120px;height:36px"
                  @click="routerPush('/inquiry_reg')">1:1 문의하기</button>
        </div>
      </div>
      <div class="lp-divider-sub5 padding-top-8"></div>
      <div class="columns is-multiline">
        <div class="column is-12">
          <div v-if="list.length>0">
            <!-- 문의 목록 -->
            <div v-for="item in list"
                 :key="item.id"
                 class="body2 flex-between board-list unselect padding-top-16 padding-bottom-16"
                 @click="routerPush(`inquiry/detail?id=${item.id}`)">
              <div>
                <span class="body5-medium" :class="{'answered':item.is_answered,'not-answered':!item.is_answered}">
                    {{ item.is_answered?'답변완료':'미답변' }}</span>
                <span class="body6 sub3 margin-left-8">{{ item.type }}</span>
                <div class="subtitle5 sub ellipsis-1 margin-top-8">{{ item.title }}</div>
              </div>
              <div class="body2 sub5">{{ datesFormat(item.created_time, 'date_3') }}</div>
            </div>
          </div>
          <div v-else>
            <div class="subtitle4 sub3 padding-68 text-center" v-if="list.length===0">문의 내역이 없습니다.</div>
            <div class="lp-divider-gray1"></div>
          </div>
          <lp-paginate :filtered="itemFiltered"
                       @getData="getData"
                       class="padding-top-80"
                       v-show="list.length>0"></lp-paginate>
        </div>
      </div>
    </div>

    <div class="mobile">
      <div class="h8 main padding-top-24">1:1 문의목록</div>
      <div class="body5 padding-top-24">전체 문의 {{ itemFiltered.count }}개</div>
      <div class="lp-divider-gray1 padding-top-8"></div>

      <div class="columns is-multiline">
        <div class="column is-12">
          <div v-if="list.length>0">
            <div v-for="item in list"
                 :key="item.id"
                 class="body2 board-list unselect padding-top-16 padding-bottom-16"
                 @click="routerPush(`inquiry/detail?id=${item.id}`)">
              <div class="body6 sub3">{{ item.type }}</div>
              <div class="subtitle7 ellipsis-1 margin-top-4">{{ item.title }}</div>

              <div class="flex-align body5-medium margin-top-8">
                  <span :class="{'answered':item.is_answered,'not-answered':!item.is_answered}">
                    {{ item.is_answered?'답변완료':'미답변' }}</span>
                <span class="body6 sub3 margin-left-8">{{ datesFormat(item.created_time, 'date_3') }}</span>
              </div>

            </div>
          </div>
          <div v-else>
            <div class="body6 sub3 padding-28 text-center">문의 내역이 없습니다.</div>
            <div class="lp-divider-gray1"></div>
          </div>
          <lp-paginate :filtered="itemFiltered"
                       @getData="getData"
                       class="margin-top-40 margin-bottom-40"
                       v-show="list.length>0"></lp-paginate>
        </div>
      </div>

      <btn-floating text="1:1 문의하기" :height="52" @clicked="routerPush('/inquiry_reg')"></btn-floating>

    </div>
  </div>
</template>
<script>
  import LpPaginate from "../component/LpPaginate";
  import PageMixin from "../../mixins/PageMixin";
  import BtnFloating from "../component/BtnFloating";
  export default {
    name: "PersonalInquiry",
    components: {BtnFloating, LpPaginate},
    mixins: [
      PageMixin
    ],
    created() {
      this.login_required = true;
      this.getData();
    },
    data() {
      return {
        list: [],
        itemFiltered: {
          cur_page: 1,
          page_num:1,
          total_page:1,
          page_length: 9
        }
      }
    },
    methods: {
      getData() {
        this.$axios.get(`user/${this.user.user_id}/inquiry/personal`, {
          params: this.itemFiltered
        }).then(res=>{
          this.list = res.data.data;
          this.itemFiltered.page_length = res.data.page_length;
          this.itemFiltered.cur_page = res.data.cur_page;
          this.itemFiltered.total_page = res.data.total_page;
          this.itemFiltered.count = res.data.count;
        })
      }
    }
  }
</script>
<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .answered
  .not-answered
    padding 3px 8px
    border-radius 4px

  .answered
    background-color $primary-light2
    color $primary

  .not-answered
    background-color $gray3
    color $sub2

  .board-list
    border-bottom 1px solid $gray1
</style>
